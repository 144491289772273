var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h4',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$_strings.approval.INPROCESS))]),_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.itemsTable,"sort-by":"calories","item-key":"index","options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page },"server-items-length":_vm.ListTotalEntry},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.getNumber(index)))])]}},{key:"item.requestDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.requestDate)))]),_c('p',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.timeFormat(item.requestDate)))])]}},{key:"item.process",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.handleAction(item.defWorkflowId, item.id)}}},[_vm._v(" "+_vm._s(item.process)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatStatus(item.status)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.handleAction(item.defWorkflowId, item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-find ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.VIEW_DETAIL))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }