<template>
  <v-container fluid>
    <h4 class="mb-5">{{$_strings.approval.INBOX}}</h4>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="items"
      class="elevation-1 table"
      item-key="index"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      :server-items-length="ListTotalEntry"
      :item-class="itemRowBackground"
    >
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ getNumber( index ) }}</span>
      </template>
      <template v-slot:[`item.process`]="{ item }">
        <a
          @click="handleAction(item, item.defWorkflowId, item.id)"
        >
          {{item.process}}
        </a>
      </template>
      <template v-slot:[`item.requestDate`]="{ item }">
        <span>{{ dateFormat(item.requestDate) }}</span>
        <p class="grey--text text--darken-2">{{ timeFormat(item.requestDate) }}</p>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ formatStatus(item.status) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip v-if="!hideButtonAction.includes(item.defWorkflowId)" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="handleAction(item, item.defWorkflowId, item.id, { tab: 1 }, { approvalAction: 1 })"
              color="primary"
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-file-document-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.approval.ACCEPTED}}</span>
        </v-tooltip>
        <v-tooltip v-if="!hideButtonAction.includes(item.defWorkflowId)" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="handleAction(item, item.defWorkflowId, item.id, { tab: 1 }, { approvalAction: 2 })"
              color="primary"
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-file-document-edit
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.approval.REVISI}}</span>
        </v-tooltip>
        <v-tooltip v-if="!hideButtonAction.includes(item.defWorkflowId)" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="handleAction(item, item.defWorkflowId, item.id, { tab: 1 }, { approvalAction: 3 })"
              color="primary"
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-close-circle
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.approval.REJECT}}</span>
        </v-tooltip>
        <v-tooltip  bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="handleAction(item, item.defWorkflowId, item.id)"
              color="primary"
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="item.status === 'REVISE' && hideButtonAction.includes(item.defWorkflowId)">
                mdi-pencil
              </v-icon>
              <v-icon v-else>
                mdi-file-find
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{item.status === 'REVISE' && hideButtonAction.includes(item.defWorkflowId) ?
              $_strings.common.EDIT :
              $_strings.common.VIEW_DETAIL
            }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import {
  dateFormat,
  timeFormat,
  skipEmptyStringObject,
  statusActionWorkflow,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  mounted() {
    this.getInboxUnread();
  },
  data() {
    return {
      isLoading: false,
      ListTotalEntry: 0,
      headers: [
        {
          text: this.$_strings.common.NUMBER,
          value: 'number',
          sortable: false,
        },
        {
          text: this.$_strings.common.DATE,
          value: 'requestDate',
        },
        {
          text: this.$_strings.common.PROCESS,
          value: 'process',
        },
        {
          text: this.$_strings.contract.header.COMPANY_NAME,
          value: 'companyName',
          width: 200,
        },
        {
          text: this.$_strings.approval.CREATED_AT,
          value: 'requester',
        },
        {
          text: this.$_strings.common.NOTE,
          value: 'notes',
        },
        {
          text: this.$_strings.approval.STATUS_APPROVAL,
          value: 'status',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'actions',
          sortable: false,
        },
      ],
      pagination: defaultPagination(),
      items: [],
      hideButtonAction: [1, 15, 99],
    };
  },

  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary',
        cellClass: 'clickable',
      }));
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    async getInboxUnread() {
      const res = await this.$_services.approval.getInboxUnread();
      this.$store.dispatch('user/setInboxUnread', res.data);
    },
    fetchData() {
      const filters = skipEmptyStringObject({
        page: this.pagination.page - 1,
        size: this.pagination.itemsPerPage === -1 ? this.ListTotalEntry : this.pagination.itemsPerPage,
        sort: handleSortBy({ defaultValue: 'requestDate,DESC', sortBy: this.pagination.sortBy, sortDesc: this.pagination.sortDesc }),
      });
      this.isLoading = true;
      this.$_services.approval.getListInbox(filters)
        .then((result) => {
          const data = [];
          result.data.contents.forEach((res) => {
            if (res.notes === 'APPROVE_READY_TO_SUPPORT') {
              data.push({
                ...res,
                status: 'Disetujui dengan catatan',
                notes: 'Silahkan melengkapi dokumen',
              });
            } else {
              data.push(res);
            }
          });
          this.items = data;
          this.ListTotalEntry = result.data.totalData;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async setIsRead(item) {
      const { id, isRead } = item;
      if (isRead) {
        return;
      }
      try {
        this.$root.$loading.show();
        await this.$_services.approval.setIsRead(id);

        const updateCountInboxUnread = Number(this.$store.getters['user/inboxUnread']) - 1;
        this.$store.dispatch('user/setInboxUnread', updateCountInboxUnread);

        return true;
      } finally {
        this.$root.$loading.hide();
      }
    },
    async handleAction(item, defWorkflowId, id, { tab = 0 } = {}, { approvalAction = null } = {}) {
      try {
        await this.setIsRead(item);
        if (defWorkflowId === 15) tab = 1; // TAB 1 IS DOCUMENT SUPPORT
        const query = {
          tab,
        };
        if (approvalAction) query.approvalAction = approvalAction;
        this.$router.push({
          name: 'skeleton-loaders',
          params: {
            page: 'inbox',
            id,
            defWorkflowId,
          },
          query,
        });
      } catch {
        return false;
      }
    },
    itemRowBackground(item) {
      return item.isRead ? null : 'font-weight-bold';
    },
    getNumber(index) {
      const x = this.pagination.page * this.pagination.itemsPerPage;
      return x - this.pagination.itemsPerPage + index + 1;
    },
    formatStatus(status) {
      return statusActionWorkflow(status);
    },
  },
};
</script>
